import * as React from "react";
import {
  TopNav,
  Banner,
  PageDivider,
  OurWork,
  AboutUs,
  AvailableLots,
  ContactUs,
  Footer,
  SeoHeader,
} from "../components";
import "../styles/app.scss";

const NAV_OPTIONS = [
  { label: "About us", href: "#about-us" },
  { label: "Our Work", href: "#image-gallery" },
  { label: "Lots", href: "#lots" },
];

// markup
const IndexPage = () => {
  return (
    <div>
      <SeoHeader />
      <TopNav dynamicBg navOptions={NAV_OPTIONS} />
      <Banner />
      <PageDivider />
      <AboutUs />
      <AvailableLots />
      <OurWork />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default IndexPage;
